<script lang="ts">
    // TODO: @Uzayn, Add Toasts when svelte french toast is implemented
    import { createEventDispatcher } from 'svelte';
    import * as AlertDialog from '$lib/components/ui/alert-dialog/index.js';
    import { Button } from '$lib/components/ui/button/index.js';

    interface Profile {
        id: number;
        name: string;
        username: string;
        avatar: string;
        cover: string;
        email_verified_at: string | null;
        birthdate: string | null;
        verification?: {
            status: string;
        };
    }

    export let profile: Profile;
    export let isListMode: boolean = false;
    export let isListManageable: boolean = false;
    export let listId: number | null = null;

    const dispatch = createEventDispatcher();

    function getBaseUrl(): string {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    async function removeListMember(userId: number) {
        if (!listId) return;

        const baseUrl = getBaseUrl();
        const requestUrl = `${baseUrl}/my/lists/members/delete`;

        try {
            const response = await fetch(requestUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
                },
                body: JSON.stringify({
                    list_id: listId,
                    user_id: userId,
                }),
            });

            const result = await response.json();

            if (result.success) {
                dispatch('memberRemoved', { userId });
                // success toast here
            } else {
                // error toast here
            }
        } catch (error) {
            console.error('Error removing list member:', error);
            // error toast here
        }
    }

    function handleRemove() {
        removeListMember(profile.id);
    }
</script>

<div class="suggestion-box mt-3 overflow-hidden rounded-lg border" data-memberuserid="{profile.id}">
    <a href="/{profile.username}" class="relative block">
        <div class="card-img suggestion-header-bg h-48 bg-gray-200">
            <img src="{profile.cover}" alt="" class="absolute inset-0 h-full w-full object-cover" />
            <div class="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
        </div>
        <div class="absolute bottom-0 left-0 right-0 flex items-end p-4">
            <div class="mr-4 flex-shrink-0">
                <img
                    src="{profile.avatar}"
                    alt="{profile.name}"
                    class="h-16 w-16 rounded-full border-2 border-white object-cover"
                />
            </div>
            <div class="flex-grow overflow-hidden">
                <div class="truncate text-lg font-bold text-white">
                    {profile.name}
                    {#if profile.email_verified_at && profile.birthdate && profile.verification && profile.verification.status === 'verified'}
                        <svg
                            class="ml-1 inline-block h-5 w-5 text-blue-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>
                    {/if}
                </div>
                <div class="truncate text-sm text-gray-300">@{profile.username}</div>
            </div>
        </div>
    </a>
    {#if isListMode && isListManageable && listId}
        <div class="absolute right-2 top-2">
            <AlertDialog.Root>
                <AlertDialog.Trigger asChild let:builder>
                    <Button
                        builders="{[builder]}"
                        variant="ghost"
                        size="icon"
                        class="h-8 w-8 bg-black bg-opacity-50 text-white hover:bg-opacity-70"
                    >
                        <svg
                            class="h-4 w-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            ></path>
                        </svg>
                    </Button>
                </AlertDialog.Trigger>
                <AlertDialog.Content>
                    <AlertDialog.Header>
                        <AlertDialog.Title>Remove Member</AlertDialog.Title>
                        <AlertDialog.Description>
                            Are you sure you want to remove {profile.name} from this list? This action cannot be undone.
                        </AlertDialog.Description>
                    </AlertDialog.Header>
                    <AlertDialog.Footer>
                        <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
                        <AlertDialog.Action on:click="{handleRemove}">Remove</AlertDialog.Action>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog.Root>
        </div>
    {/if}
</div>
